import React from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";

import { Section, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";

import officeImage from "../assets/images/agency/office.jpg";
import kelvin from "../assets/images/agency/kelvin.png";
import yucho from "../assets/images/agency/yucho.png";
import tapasvi from "../assets/images/agency/tapasvi.jpg";

const ProfileImg = styled.img`
  border-radius: 50%;
  transition: 0.4s;
  max-width: 200px;

  &:hover {
    transform: scale(0.8) rotate(-16deg);
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
  }
`;

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.dark} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 1.63px;
  transition: 0.4s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary} !important;
    transform: translateY(-5px);
  }
`;

const About = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="10">
                <Box>
                  <Title className="my-4">
                    Our mission is to be the agency that ends all agencies.
                  </Title>
                </Box>
              </Col>
            </Row>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Box>
                  <Text
                    variant="p"
                    css={`
                    `}
                  >
                    Agencies are expensive and hiring an agency for any project can become a money sink.
                    Our core principle is to build solutions that are easily manageable, architecturally sound, and scale across your organization.
                    We want to make sure you never have to hire us or any other agency ever again.
                  </Text>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img src={officeImage} alt="" className="img-fluid w-100" />
            </Box>
          </Container>
        </Section>
        <Section>
          <Container>
              <Row className="justify-content-center text-center">
                <Col lg="10">
                  <Box>
                    <Title className="mb-4">
                      Meet the team
                    </Title>
                    <Text
                      variant="p"
                      css={`
                      `}
                    >
                       Our team consists of full-stack product engineers who possess over 15 years of experience in the enterprise software space - shipping products for Fortune 500 companies that serve hundreds of millions of users.
                       Not only are we experts in our craft, we have an understanding of the product, design, project management, and execution steps required to deliver projects on-time and on-budget.
                    </Text>
                  </Box>
                </Col>
              </Row>

              <Box pt={["4rem", null, null, "6.25rem"]}>
                <Row className="justify-content-center text-center">
                  <Col lg="4">
                    <ProfileImg src={kelvin} alt="Kelvin Nguyen" className="" />
                    <div className="my-4">
                      <Title variant="cardLg" >
                        Kelvin Nguyen
                      </Title>
                      <Text variant="tag">
                        Co-founder & Engineer
                      </Text>
                      <div className="my-2">
                        {/* <LinkSocial
                          href="https://www.linkedin.com/in/kelyvin/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mr-3"
                        >
                          <FaLinkedin />
                        </LinkSocial>
                        <LinkSocial
                          href="https://twitter.com/kelyvinN"
                          target="_blank"
                          rel="noopener noreferrer">
                          <FaTwitterSquare />
                        </LinkSocial> */}
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <ProfileImg src={yucho} alt="Yucho Ho" className="" />
                    <div className="my-4">
                      <Title variant="cardLg">
                        Yucho Ho
                      </Title>
                      <Text variant="tag">
                        Founding Engineer
                      </Text>
                      <div className="my-2">
                        {/* <LinkSocial
                          href="https://www.linkedin.com/in/yuchoho/"
                          target="_blank"
                          rel="noopener noreferrer">
                          <FaLinkedin />
                        </LinkSocial> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Box>
            </Container>
          </Section>
        <Box pb={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default About;
